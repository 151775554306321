<template>
  <b-card>
    <b-row>
      <b-col>
        <DxDataGrid
          v-if="bShowItems"
          :data-source="items"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
        >
          <DxPaging :page-size="15" />
          <DxExport :enabled="true" />
          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />
          <DxHeaderFilter :visible="true" />
          <DxFilterRow :visible="true" />
          <DxPager
            :visible="true"
            infoText="Página {0} of {1} ({2} registros)"
            :allowed-page-sizes="pageSizes"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxColumn
            v-for="(column, idx) in columns"
            :key="idx"
            :data-field="column.dataField"
            :caption="column.caption"
            :data-type="column.type"
            :alignment="column.alignment"
            :group-index="column.group"
            :format="column.format"
          />
        </DxDataGrid>
        <div v-else>
          <div
            v-if="bLoading"
            class="d-flex justify-content-center aling-items-center"
          >
            <img src="assets/img/loader.gif" alt="Loading..." />
          </div>
          <div v-else class="text-center">
            <div class="text-center">
              <img src="assets/img/empty.png" alt="Empty" />
              <p class="text-muted">No hay información para mostrar</p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import axios from "axios";
import {
  DxDataGrid,
  DxPaging,
  DxColumn,
  DxSearchPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxExport,
  DxGroupPanel,
} from "devextreme-vue/data-grid";
export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxColumn,
    DxSearchPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxPager,
    DxExport,
    DxGroupPanel,
  },
  props: {
    oData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columns: [],
      pageSizes: [5, 15, 40, 50, 100],
      items: [],
      bLoading: false,
    };
  },
  mounted() {
    this.getItems();
  },
  methods: {
    async getItems() {
      this.bLoading = true;
      const { data } = await axios.get(`admin-avisos/${this.oData.id}`);

      this.items = data.items;
      this.columns = data.columns;
      this.bLoading = false;
    },
  },
  computed: {
    bShowItems() {
      return this.items && this.items.length !== 0;
    },
  },
};
</script>
