<template>
  <div>
    <AdminWarningV2 @getRowData="getRowData" />
    <b-modal
      ref="my-modal"
      hide-footer
      title="Formulario Asignar evento"
      size="lg"
    >
      <div>
        <b-row class="justify-content-center">
          <b-col>
            <div class="form-group">
              <label>RID: {{ work.mRID }}</label>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <b-col cols="12">
            <div class="form-group">
              <label>Descripcion</label>
              <b-form-textarea
                id="textarea"
                v-model="work.WorkTask.subject"
                placeholder="describa la descripción"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-center">
          <b-col cols="4">
            <b-button variant="primary" @click="postTask()" ref="kt_post_task"
              >Enviar</b-button
            >
          </b-col>
          <b-col cols="4">
            <b-button variant="outline-danger" block @click="hiddenModal"
              >Cerrar</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import AdminWarningV2 from "./warnings/AdminWarningV2.vue";
import toastr from "toastr";

export default {
  components: {
    AdminWarningV2,
  },

  data() {
    return {
      work: {
        mRID: "",
        name: "taskrequest",
        WorkTask: {
          name: "12248",
          subject: "",
        },
      },
      isLoading: false,
    };
  },
  methods: {
    resetForm() {
      this.work.mRID = "";
      this.work.WorkTask.subject = "";
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hiddenModal() {
      this.work.WorkTask.subject = "";
      this.$refs["my-modal"].hide();
    },
    async postTask() {
      if (
        !this.work.mRID ||
        !this.work.name ||
        !this.work.WorkTask.name ||
        !this.work.WorkTask.subject
      ) {
        toastr.info("Todos los campos deben ser diligenciados!");
      } else {
        this.loaderSpinClasss();
        try {
          const response = await axios.post(
            `/works/${this.work.mRID}/worksTasks/create`,
            { work: this.work }
          );
          if (response.status === 200) {
            this.removeLoaderSpinClass();
            toastr.success(
              "todo correcto - se asigno correctamente el evneto!"
            );
            this.resetForm();
            this.hiddenModal();
          }
        } catch (error) {
          console.log(error);
          toastr.error("Algo salio mal - comuniquese con soporte!");
          this.removeLoaderSpinClass();
        }
      }
    },
    getRowData(data) {
      this.work.mRID = data.mRID;
      this.showModal();
    },
    loaderSpinClasss() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.kt_post_task;
      submitButton.classList.add(
        "disabled",
        "spinner",
        "spinner-light",
        "spinner-left"
      );
    },
    removeLoaderSpinClass() {
      this.isLoading = !this.isLoading;
      const submitButton = this.$refs.kt_post_task;
      submitButton.classList.remove(
        "disabled",
        "spinner",
        "spinner-light",
        "spinner-left"
      );
    },
  },
};
</script>
